exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-learning-hub-blog-js": () => import("./../../../src/pages/learning-hub/blog.js" /* webpackChunkName: "component---src-pages-learning-hub-blog-js" */),
  "component---src-pages-learning-hub-video-tutorials-index-js": () => import("./../../../src/pages/learning-hub/video-tutorials/index.js" /* webpackChunkName: "component---src-pages-learning-hub-video-tutorials-index-js" */),
  "component---src-pages-qr-appstore-js": () => import("./../../../src/pages/qr-appstore.js" /* webpackChunkName: "component---src-pages-qr-appstore-js" */),
  "component---src-pages-search-results-js": () => import("./../../../src/pages/search-results.js" /* webpackChunkName: "component---src-pages-search-results-js" */),
  "component---src-templates-article-article-js": () => import("./../../../src/templates/article/Article.js" /* webpackChunkName: "component---src-templates-article-article-js" */),
  "component---src-templates-campaign-index-js": () => import("./../../../src/templates/campaign/index.js" /* webpackChunkName: "component---src-templates-campaign-index-js" */),
  "component---src-templates-credit-cards-index-js": () => import("./../../../src/templates/credit-cards/index.js" /* webpackChunkName: "component---src-templates-credit-cards-index-js" */),
  "component---src-templates-digital-banking-index-js": () => import("./../../../src/templates/digital-banking/index.js" /* webpackChunkName: "component---src-templates-digital-banking-index-js" */),
  "component---src-templates-faq-fa-qs-answers-template-js": () => import("./../../../src/templates/faq/FAQsAnswersTemplate.js" /* webpackChunkName: "component---src-templates-faq-fa-qs-answers-template-js" */),
  "component---src-templates-faq-fa-qs-category-js": () => import("./../../../src/templates/faq/FAQsCategory.js" /* webpackChunkName: "component---src-templates-faq-fa-qs-category-js" */),
  "component---src-templates-legal-articles-index-js": () => import("./../../../src/templates/legal-articles/index.js" /* webpackChunkName: "component---src-templates-legal-articles-index-js" */),
  "component---src-templates-page-template-index-js": () => import("./../../../src/templates/pageTemplate/index.js" /* webpackChunkName: "component---src-templates-page-template-index-js" */),
  "component---src-templates-redirect-index-js": () => import("./../../../src/templates/redirect/index.js" /* webpackChunkName: "component---src-templates-redirect-index-js" */),
  "component---src-templates-video-tutorials-index-js": () => import("./../../../src/templates/video-tutorials/index.js" /* webpackChunkName: "component---src-templates-video-tutorials-index-js" */)
}

